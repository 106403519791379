import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

function ImageHeading({ image, alt, heading, background }) {
  return (
    <React.Fragment>
      <div className={`flex justify-center pt-12 ${background}`}>
        <div className="max-w-4xl flex-1">
          <GatsbyImage className="h-32 py-48 md:py-64" image={image} alt={alt} />
        </div>
      </div>

      <div className="text-center -my-16 z-10">
        <h2 className="bg-yellow text-5xl font-bold inline-block px-6 py-4 font-display">
          {heading}
        </h2>
      </div>
    </React.Fragment>
  );
}

export default ImageHeading;
