import React from "react";

import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import ContactLink from "../components/contactLink";
import ImageHeading from "../components/imageHeading";

function ProjectsPage({ data }) {
  return (
    <Layout>
      <div className="bg-grey-dark px-8 py-20">
        <div className="max-w-4xl mx-auto">
          <h3 className="font-display font-bold text-4xl text-white mb-4">
            Selected Projects
          </h3>
          <p className="text-xl text-white opacity-75">
            We specialise in producing unique and creative merchandise that
            connects with fans. We'll work closely with you to identify the key
            products that appeal to your audience and support you with creative
            marketing campaigns.
          </p>
        </div>
      </div>

      <ImageHeading
        background="bg-salmon"
        image={data.lucyImage1.childImageSharp.gatsbyImageData}
        alt="Lucy Moon in her Cleo Tee"
        heading="Lucy Moon"
      />

      <div className="bg-grey-dark px-8 pb-20 pt-32">
        <div className="max-w-4xl mx-auto">
          <h3 className="font-display font-bold text-2xl text-white mb-4">
            Creating an Eco-Friendly Clothing Brand
          </h3>
          <p className="text-xl text-white opacity-75">
            When we embarked on creating a clothing brand for influencer Lucy
            Moon, we knew from the start that sustainability would be at the
            heart of every choice we made. Moon Merch is rooted in Lucy’s
            passion for creating pieces with not only lovely designs and
            textures but with eco-friendly materials produced in factories that
            provide fair working conditions.
            <br />
            <br />
            This green philosophy has received overwhelming support from
            customers and inspired other IRL clients who have also begun to
            explore ways to incorporate sustainable products and packaging into
            their own shops.
          </p>
        </div>
      </div>

      <div className="bg-grey-dark">
        <div className="flex flex-wrap max-w-4xl mx-auto items-center py-8">
          <div className="flex-1 max-w-xl md:w-1/2 ">
            <GatsbyImage
              className="h-64 py-64"
              image={data.lucyImage2.childImageSharp.gatsbyImageData}
              alt="Lucy Moon in her Cleo Tee"
            />
          </div>

          <div className="text-white flex-initial p-8 md:w-1/3">
            <div className="text-xl">
              <p>The Cleo Tee</p>
              <p className="opacity-75">
                Working with one of our most trusted suppliers - who are at the
                forefront of sustainable manufacturing - this 100% combed cotton
                t-shirt is both high quality and ethical. The t-shirt of muted
                palette dreams!
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap max-w-4xl mx-auto items-center py-8">
          <div className="flex-1 max-w-xl md:w-1/2 ">
            <GatsbyImage
              className="h-64 py-64"
              image={data.lucyImage3.childImageSharp.gatsbyImageData}
              alt="Lucy Moon with her Goddess Energy Tote"
            />
          </div>

          <div className="text-white flex-initial p-8 md:w-1/3">
            <div className="text-xl">
              <p>The Goddess Energy Tote</p>
              <p className="opacity-75">
                100% recycled, 100% empowering. Originally designed as a
                convention exclusive, online demand for this tote was so
                enthusiastic that we made it a shop staple.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ImageHeading
        background="bg-purple"
        image={data.colinImage1.childImageSharp.gatsbyImageData}
        alt="Colin Furze with his Saftey Tie Mug"
        heading="Colin Furze"
      />

      <div className="bg-grey-dark px-8 pb-20 pt-32">
        <div className="max-w-4xl mx-auto">
          <h3 className="font-display font-bold text-2xl text-white mb-4">
            Inventing for an Inventor
          </h3>
          <p className="text-xl text-white opacity-75">
            Keeping up with an explosive character like YouTube inventor and
            crazy contraption creator Colin Furze takes the right tools for the
            job, starting with a strong cup of tea. We launched Colin’s shop
            with one simple product that he knew his audience would love; an
            everyday mug with his iconic “Keep Calm and Wear a Safety Tie”
            phrase. The mug was a runaway hit, exceeding expectations with over
            500 units sold in the first week.
            <br />
            <br />
            The mug later featured in his video “
            <OutboundLink
              href="https://www.youtube.com/watch?v=utTrqCptxRo"
              className="underline"
            >
              Building a Furze Tea Machine
            </OutboundLink>
            ” in collaboration with Yorkshire Tea, amassing over 1.5 million
            views to date and further boosting sales.
          </p>
        </div>
      </div>

      <div className="bg-grey-dark">
        <div className="flex flex-wrap max-w-4xl mx-auto items-center py-8">
          <div className="flex-1 max-w-xl md:w-1/2 ">
            <GatsbyImage
              className="h-64 py-64"
              image={data.colinImage2.childImageSharp.gatsbyImageData}
              alt="Colin Furze with his Safety Tie"
            />
          </div>

          <div className="text-white flex-initial p-8 md:w-1/3">
            <div className="text-xl">
              <p>The Safety Tie</p>
              <p className="opacity-75">
                Colin has long been associated with his classic engineer's look
                of short-sleeved shirt and tie. Featuring custom branded
                packaging (making it ideal as a gift) and produced to Colin's
                high technical standards the 'Safety Tie' sold well beyond
                expectations.
              </p>
            </div>
          </div>
        </div>
      </div>

      <ImageHeading
        background="bg-mint"
        image={data.danielImage1.childImageSharp.gatsbyImageData}
        alt="Daniel Howell"
        heading="Daniel Howell"
      />

      <div className="bg-grey-dark px-8 pb-20 pt-32">
        <div className="max-w-4xl mx-auto">
          <h3 className="font-display font-bold text-2xl text-white mb-4">
            Back to class with sass
          </h3>
          <p className="text-xl text-white opacity-75">
            Pushing the boundaries between sarcastic and relatable, Daniel
            Howell’s vision for his products are anything but traditional. His
            collections customise familiar products with an all-black aesthetic
            and humorous phrases to create wildly popular sell-out products.
          </p>
        </div>
      </div>

      <div className="bg-grey-dark">
        <div className="flex flex-wrap max-w-4xl mx-auto items-center pt-8">
          <div className="flex-1 max-w-xl md:w-1/2">
            <GatsbyImage
              className="h-64 py-64"
              image={data.danielImage2.childImageSharp.gatsbyImageData}
              alt="Daniel in the Sequined Skull sweater"
            />
          </div>
          <div className="flex-1 max-w-xl md:w-1/2">
            <GatsbyImage
              className="h-64 py-64"
              image={data.danielImage3.childImageSharp.gatsbyImageData}
              alt="Sarah wearing the Don't Talk To Me Scarf"
            />
          </div>
        </div>
      </div>

      <div className="bg-grey-dark p-8 pb-16 text-center">
        <p className="text-xl text-white opacity-75 max-w-4xl mx-auto text-left">
          Daniel's collections feature custom embroidery and knits, modified
          garment lengths and collaborations with relevant brands (Spiral, New
          Love Club) to create consistently inventive and highly personal
          merchandise.
        </p>
      </div>

      <ImageHeading
        background="bg-salmon"
        image={data.doomedImage1.childImageSharp.gatsbyImageData}
        alt="We're All Doomed"
        heading="We're All Doomed"
      />

      <div className="bg-grey-dark px-8 pb-20 pt-32">
        <div className="max-w-4xl mx-auto">
          <h3 className="font-display font-bold text-2xl text-white mb-4">
            Shoreditch Popup Shop
          </h3>
          <p className="text-xl text-white opacity-75">
            To kick off Daniel Howell's 2022 Tour in style,
            we created the <i>We're All Doomed Popup Shop</i> in Shoreditch, London.
            Held across two days in September, the shop featured an eclipse cloud wall where
            customers could take selfies and an enormous message blackboard where fans were able to
            scribe their names and write messages of encouragement to Daniel.
          </p>
        </div>
      </div>

      <div className="bg-grey-dark">
        <div className="flex flex-wrap max-w-4xl mx-auto items-center py-8">
          <div className="flex-1 max-w-xl md:w-1/2 ">
            <GatsbyImage
              className="h-64 py-64"
              image={data.doomedImage2.childImageSharp.gatsbyImageData}
              alt="Two fans purchase merch at the checkout"
            />
          </div>

          <div className="text-white flex-initial p-8 md:w-1/3">
            <div className="text-xl">
              <p>A Fan Space</p>
              <p className="opacity-75">
                We're really proud of how we turned an empty shop with
                plain white walls into an exciting and welcoming space.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-indigo p-16 text-center">
        <p className="max-w-4xl mx-auto text-xl text-white opacity-75 pb-8">
          Want to find out who else we've helped?
        </p>
        <Link
          to="/clients"
          className="text-xl bg-white text-indigo mt-4 px-6 py-4 inline-block shadow hover:shadow-lg"
        >
          See Clients &rarr;
        </Link>
      </div>

      <ContactLink />
    </Layout>
  );
}

export const fluidImage = graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(quality: 85, layout: FULL_WIDTH)
  }
}
`;

export const query = graphql`
  query ProjectsImageQuery {
    lucyImage1: file(relativePath: { eq: "LucyMoon-CleoTee-1.jpg" }) {
      ...fluidImage
    }

    lucyImage2: file(relativePath: { eq: "LucyMoon-CleoTee-2.jpg" }) {
      ...fluidImage
    }

    lucyImage3: file(relativePath: { eq: "LucyMoon-Tote-1.jpg" }) {
      ...fluidImage
    }

    colinImage1: file(relativePath: { eq: "ColinFurze-Mug-1.jpg" }) {
      ...fluidImage
    }

    colinImage2: file(relativePath: { eq: "ColinFurze-Tie-1.jpg" }) {
      ...fluidImage
    }

    colinImage3: file(relativePath: { eq: "ColinFurze-Tie-2.jpg" }) {
      ...fluidImage
    }

    danielImage1: file(relativePath: { eq: "DanielHowell-DeskGum-1.jpg" }) {
      ...fluidImage
    }

    danielImage2: file(relativePath: { eq: "DanielHowell-SkullSweater-2.jpg" }) {
      ...fluidImage
    }

    danielImage3: file(relativePath: { eq: "DanielHowell-DTTMScarf-1.jpg" }) {
      ...fluidImage
    }

    doomedImage1: file(relativePath: { eq: "Doomed-MessageBoard.jpg" }) {
      ...fluidImage
    }

    doomedImage2: file(relativePath: { eq: "Doomed-Till.jpg" }) {
      ...fluidImage
    }
  }
`;

export default ProjectsPage;

export function Head() {
  return (
    <SEO title="Projects" />
  )
}
